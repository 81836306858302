import './style.css';
import QRCode from 'qrcode';

console.log("test")
const MAX_CHARS = 5000;

const app = document.querySelector<HTMLDivElement>('#app')!;

app.innerHTML = `
  <div class="container">
    <h1 id="homeLink">
      <img src="/envelope.svg" alt="Envelope" class="title-icon" />
      heyjust.fyi
    </h1>
    <div id="inputSection">
      <input type="text" id="recipientName" placeholder="Recipient's name (optional)" />
      <input type="text" id="senderName" placeholder="Your name (optional)" />
      <textarea id="textInput" placeholder="Enter your text here (max ${MAX_CHARS} characters)" maxlength="${MAX_CHARS}" rows="10"></textarea>
      <div class="button-container">
        <button id="copyURLButton">Copy URL</button>
        <button id="generateQRButton" disabled>Generate & Copy QR Code</button>
      </div>
      <p id="charCount">0 / ${MAX_CHARS}</p>
      <p id="qrCodeInfo">QR Code generation available for messages up to 200 characters</p>
      <span id="copyIndicator" style="display: none;">✓ Copied!</span>
      <div id="qrCodeContainer" style="display: none;">
        <img id="qrCode" alt="QR Code" />
      </div>
    </div>
    <div id="messageSection" style="display: none;">
      <div id="envelope">
        <div class="envelope-content">heyjust.fyi</div>
      </div>
      <div id="letter" style="display: none;">
        <p id="messageContent"></p>
        <p class="signature">From: <span id="messageSender"></span></p>
        <button id="respondButton">Respond</button>
      </div>
    </div>
  </div>
`;


const recipientName = document.querySelector<HTMLInputElement>('#recipientName')!;
const senderName = document.querySelector<HTMLInputElement>('#senderName')!;
const textInput = document.querySelector<HTMLTextAreaElement>('#textInput')!;
const copyURLButton = document.querySelector<HTMLButtonElement>('#copyURLButton')!;
const generateQRButton = document.querySelector<HTMLButtonElement>('#generateQRButton')!;
const charCount = document.querySelector<HTMLParagraphElement>('#charCount')!;
const copyIndicator = document.querySelector<HTMLSpanElement>('#copyIndicator')!;
const inputSection = document.querySelector<HTMLDivElement>('#inputSection')!;
const messageSection = document.querySelector<HTMLDivElement>('#messageSection')!;
const qrCodeContainer = document.querySelector<HTMLDivElement>('#qrCodeContainer')!;
const qrCodeImg = document.querySelector<HTMLImageElement>('#qrCode')!;
const envelope = document.querySelector<HTMLDivElement>('#envelope')!;
const letter = document.querySelector<HTMLDivElement>('#letter')!;
const messageSender = document.querySelector<HTMLSpanElement>('#messageSender')!;
const messageContent = document.querySelector<HTMLParagraphElement>('#messageContent')!;
const respondButton = document.querySelector<HTMLButtonElement>('#respondButton')!;
const homeLink = document.querySelector<HTMLHeadingElement>('#homeLink')!;

envelope.addEventListener('click', () => {
  envelope.style.opacity = '0';
  envelope.style.transform = 'scale(0.9)';
  setTimeout(() => {
    envelope.style.display = 'none';
    letter.style.display = 'block';
    setTimeout(() => {
      letter.classList.add('show');
    }, 50);
  }, 300);
});

function simpleCipher(text: string): string {
  return btoa(encodeURIComponent(text));
}

function simpleDecipher(encodedText: string): string {
  return decodeURIComponent(atob(encodedText));
}

function generateURL(text: string, senderName: string, recipientName: string): string {
  const encodedText = simpleCipher(text);
  const encodedSenderName = simpleCipher(senderName);
  const encodedRecipientName = simpleCipher(recipientName);
  return `${window.location.origin}${window.location.pathname}?t=${encodedText}&s=${encodedSenderName}&r=${encodedRecipientName}`;
}

function updateCharCount() {
  const count = textInput.value.length;
  charCount.textContent = `${count} / ${MAX_CHARS}`;
  
  if (count <= 200) {
    generateQRButton.disabled = false;
    generateQRButton.title = "Generate and copy QR code";
  } else {
    generateQRButton.disabled = true;
    generateQRButton.title = "QR code generation is only available for messages up to 200 characters";
  }
}

function showCopyIndicator() {
  copyIndicator.style.display = 'inline';
  setTimeout(() => {
    copyIndicator.style.display = 'none';
  }, 2000);
}

copyURLButton.addEventListener('click', () => {
  const text = textInput.value.trim();
  const sender = senderName.value.trim();
  const recipient = recipientName.value.trim();
  if (text) {
    const url = generateURL(text, sender, recipient);
    navigator.clipboard.writeText(url).then(() => {
      showCopyIndicator();
    });
  } else {
    alert('Please enter some text.');
  }
});

generateQRButton.addEventListener('click', async () => {
  const text = textInput.value.trim();
  const sender = senderName.value.trim();
  const recipient = recipientName.value.trim();
  if (text) {
    const url = generateURL(text, sender, recipient);
    try {
      const qrCodeDataUrl = await QRCode.toDataURL(url, { width: 200, margin: 2 });
      qrCodeImg.src = qrCodeDataUrl;
      qrCodeContainer.style.display = 'flex';
      const blob = await fetch(qrCodeDataUrl).then(r => r.blob());
      await navigator.clipboard.write([
        new ClipboardItem({ 'image/png': blob })
      ]);
      showCopyIndicator();
    } catch (error) {
      console.error('Error generating or copying QR code:', error);
    }
  } else {
    alert('Please enter some text.');
  }
});

textInput.addEventListener('input', updateCharCount);

respondButton.addEventListener('click', () => {
  window.location.href = window.location.origin + window.location.pathname;
});

homeLink.addEventListener('click', (e) => {
  e.preventDefault();
  window.location.href = window.location.origin + window.location.pathname;
});

// Check if there's encoded text in the URL
const urlParams = new URLSearchParams(window.location.search);
const encodedText = urlParams.get('t');
const encodedSenderName = urlParams.get('s');
const encodedRecipientName = urlParams.get('r');

if (encodedText) {
  try {
    const decodedText = simpleDecipher(encodedText);
    messageContent.textContent = decodedText;
    inputSection.style.display = 'none';
    messageSection.style.display = 'block';

    if (encodedSenderName) {
      const decodedSenderName = simpleDecipher(encodedSenderName);
      messageSender.textContent = `${decodedSenderName}`;
    } else {
      messageSender.textContent = 'Anonymous';
    }

    if (encodedRecipientName) {
      const decodedRecipientName = simpleDecipher(encodedRecipientName);
      document.querySelector<HTMLDivElement>('.envelope-content')!.textContent = decodedRecipientName;
    } else {
      document.querySelector<HTMLDivElement>('.envelope-content')!.textContent = 'Anonymous';
    }
  } catch (error) {
    // result.textContent = 'Invalid or corrupted data in URL.';
  }
}
